/**
 * Mentors listing
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";

// import Mentor from 'components/Common/Person'
// import { Link } from 'react-router-dom'

export const BUCHAREST_LINK =
  "https://en.xing-events.com/iLabsHackathonBucuresti2023";
export const BRASOV_LINK = BUCHAREST_LINK;
export const CLUJ_LINK = "https://en.xing-events.com/iLabsHackathonCluj2023";
export const IASI_LINK = "https://il.calemis.org";
export const SIBIU_LINK = BUCHAREST_LINK;
export const TIMISOARA_LINK =
  "https://www.eventbrite.com/e/innovation-labs-timisoara-hackathon-tickets-813855683257?aff=oddtdtcreator&fbclid=IwAR3WfuG673sTH7ghFAMeZkQZsffrO_bBsVkDkZQQrS0nWC9R5_ZSGuhq_Cc";

class MentorsList extends Component {
  static propTypes = {
    data: PropTypes.array,
  };

  static defaultProps = {
    data: [],
  };

  state = {
    links: [
      {
        city: "Bucharest",
        link: BUCHAREST_LINK,
      },
      {
        city: "Brasov",
        link: BRASOV_LINK,
        isFor: true,
      },
      {
        city: "Cluj",
        link: CLUJ_LINK,
      },
      {
        city: "Iasi",
        link: IASI_LINK,
      },
      {
        city: "Sibiu",
        link: SIBIU_LINK,
        isFor: true,
      },

      {
        city: "Timisoara",
        link: TIMISOARA_LINK,
      },
    ],
  };
  render() {
    // const { data } = this.props

    let toRender = this.state.links.map((element) => {
      if (
        ["Bucharest", "Brasov", "Cluj", "Timisoara", "Sibiu", "Iasi"].includes(
          element.city,
        )
      ) {
        return (
          <a href={element.link} target="_blank">
            <button id="button">
              {" "}
              Register {element.isFor ? "for" : "in"} {element.city}{" "}
            </button>
          </a>
        );
      } else {
        return (
          <a>
            <button
              onClick={() => {
                document.querySelector(".RegisterButton").click();
              }}
              id="button"
            >
              {" "}
              Register in {element.city}{" "}
            </button>
          </a>
        );
      }
    });
    return (
      <div className="RegisterNow-container">
        <div className="RegisterNow-contents">
          <h2 id="title">Pre-Register now for Innovation Labs 2024!</h2>
          <a
            className="largeTitle"
            href="https://bit.ly/InnovationLabs2024"
            target="_blank"
          >
            <button>Click here to start</button>
          </a>
          {/* <div id="list">{toRender}</div> */}
          {/* <p style = {{fontStyle:"italic" }}id="note"> */}
          {/*   *The Bucharest hackathon will house the teams for the programs in Bucharest, Brasov and Sibiu.  */}
          {/* </p> */}
          {/* <p id="note"> */}
          {/*   Registration is open until mid-March for teams from any city. Select your closest center from above (ex - register in Iasi for Suceava, in Bucharest for Constanta & Craiova, in Timisoara for Oradea). */}
          {/* </p> */}
        </div>
      </div>
    );
  }
}

MentorsList.propTypes = {
  data: PropTypes.array,
};

MentorsList.defaultProps = {
  data: [],
};

export default MentorsList;
