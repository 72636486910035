/**
 * Main container component
 */

import React, { Component } from "react";

import Menu from "./components/Menu";
import Footer from "./components/Footer";

import locate from "common/location";
// import ReactDOM from 'react-dom'
import axios from "axios";
// import IframeResizer from 'iframe-resizer-react'
import "./index.scss";

class Container extends Component {
  state = {
    partners: [],
    city: "",
    height: "100px",
  };

  componentDidMount() {
    axios
      .get("/api/partners/")
      .then((x) => this.setState({ ...this.state, partners: x.data }));

    locate((city) => this.setState({ ...this.state, city }));

    // Iframe auto-resizing
    // Uses PostMessage:
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage

    window.addEventListener("message", (event) => {
      console.log("New Post Event", event);
      if (event.origin !== "https://logos.innovationlabs.ro") {
        console.log("Invalid event", event);
        return;
      }

      console.log("✅ " + event.data.height);
      // const iframe = document.querySelector('#partners-iframe')
      // iframe.style.height = `${event.data.height}px`;

      // if ( window.screen.width > 1024 )
      //   iframe.style.height = 2300
      // else
      //   iframe.style.height = event.data

      const iframe = document.querySelector("#partners-iframe");

      // Adjusting the iframe height onload event
      iframe.onload = function () // function execute while load the iframe
      {
        // set the height of the iframe as
        // the height of the iframe content
        iframe.style.height =
          iframe.contentWindow.document.body.scrollHeight + "px";
      };
    });
  }

  render() {
    // const { partners, city } = this.state

    return (
      <div className="Container">
        <Menu />
        <div className="content">
          {this.props.children}
          {/* <Partners data={partners} city={city} /> */}
          {/* <IframeResizer
            log
            src="https://logos.innovationlabs.ro"
            style={{ width: '100%', minWidth: '100%'}}
          /> */}
          {window.location.href.split("//")[1].split("/")[1] !==
            "communities" && <Partners />}
        </div>
        <Footer />
      </div>
    );
  }
}

function Partners() {
  const [html, setHtml] = React.useState("");
  const [css, setCss] = React.useState("");

  const getData = async () => {
    const response = await axios.get("/getJson");
    setHtml(
      response.data.pagesHtml[0].html
        .replace('<body id="ixhl">', "")
        .replace("</body>", ""),
    );
    setCss(response.data.pagesHtml[0].css);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <style>{css}</style>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </React.Fragment>
  );
}

export default Container;
