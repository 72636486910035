/**
 * Main menu component
 */

import React, { Component } from "react"

import { Link } from "react-router-dom"

// import logoBig from "./img/logo-big.svg";
import badge from "./img/badge.svg";
import mobileMenu from "./img/mobileMenu.svg";
import logoBig2022 from "./img/2023-logo.png";

// import RegisterButton from "components/Common/RegisterButton"

import "./index.scss"

class Menu extends Component {
  constructor(props) {
    super(props)  

    this.state = {
      isMobile: window.innerWidth <= 900,
      menuOpen: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", (_) =>
      this.setState({ ...this.state, isMobile: window.innerWidth <= 900 })
    )
  }

  getLogo() {
    let { isMobile } = this.state
    if (isMobile) {
      return badge
    } else {
      return logoBig2022;
    }
  }

  toggleMenu() {
    this.setState({
      ...this.state,
      menuOpen: !this.state.menuOpen
    })
  }

  getMobileMenu() {
    let { isMobile } = this.state

    if (isMobile) {
      return [
        <img
          src={mobileMenu}
          alt=""
          key={0}
          onClick={this.toggleMenu}
          className="mobileMenu"
        />,
        <div key={1} className="overlay" onClick={this.toggleMenu} />
      ]
    } else {
      return [null, null]
    }
  }

  getClassName(page) {
    return window.location.href.includes(page) ? "active" : ""
  }

  render() {
    let { menuOpen } = this.state
    let menuOpenClass = menuOpen ? "open" : ""

    return (
      <div className="Menu">
        <Link to="/">
          <img src={this.getLogo()} className="logo" alt="" />
        </Link>
        {this.getMobileMenu()[0]}
        <div className={"links-block " + menuOpenClass}>
          {this.getMobileMenu()[1]}
          <Link
            onClick={this.toggleMenu}
            className={this.getClassName("program")}
            to="/program"
          >
            Program
          </Link>
          <Link
            onClick={this.toggleMenu}
            className={this.getClassName("teams")}
            to="/teams"
          >
            Teams
          </Link>
          <Link
            onClick={this.toggleMenu}
            className={this.getClassName("mentors")}
            to="/mentors"
          >
            Mentors & Juries
          </Link>
          <Link
            onClick={this.toggleMenu}
            className={this.getClassName("communities")}
            to="/communities"
          >
            Communities
          </Link>
          <Link
            onClick={this.toggleMenu}
            className={this.getClassName("tech")}
            to="/tech"
          >
            Tech
          </Link>
          {/*<Link
            onClick={this.toggleMenu}
            className={this.getClassName('collaborators')}
            to='/collaborators'
          >
            Collaborators
          </Link>
          */}
          <Link
            onClick={this.toggleMenu}
            className={this.getClassName("whoweare")}
            to="/whoweare"
          >
            Who we are
          </Link>
        </div>
        {/* <RegisterButton /> */}
      </div>
    )
  }
}

export default Menu
