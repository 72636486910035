/**
 * Website footer
 */

import React, { Component } from "react";

import { Link } from "react-router-dom";

import Contact from "components/Common/ContactModal";

import logo from "../Menu/img/2023-logo.png";

import facebook from "./img/facebook.svg";
import email from "./img/email.svg";
// import twitter from "./img/twitter.svg";
import vimeo from "./img/vimeo.svg";
import flickr from "./img/flickr.svg";
import instagram from "./img/instagram.png";
import linkedin from "./img/linkedin.png";

import "./index.scss";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  toggleModal = (_) =>
    this.setState({
      ...this.state,
      modalOpen: !this.state.modalOpen,
    });

  render() {
    let { modalOpen } = this.state;

    return (
      <div className="Footer">
        <Contact visible={modalOpen} onClose={this.toggleModal} />
        <div className="social-block">
          <img src={logo} alt="" className="logo" />
          <div className="social-icons">
            {/* https://css-tricks.com/use-target_blank/ */}
            <a href="http://www.facebook.com/ILabsRo">
              <img src={facebook} alt="facebook" />
            </a>
            <img onClick={this.toggleModal} src={email} alt="email" />
            <a href="https://www.instagram.com/ilabsro/">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="http://www.vimeo.com/channels/innovationlabs">
              <img src={vimeo} alt="vimeo" />
            </a>
            <a href="https://www.flickr.com/photos/innovationlabs/albums">
              <img src={flickr} alt="flickr" />
            </a>
            <a href="https://www.linkedin.com/company/ilabsro/">
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="lists-block">
          <ul>
            <li>More</li>
            {/* <li>
              <a href="/">Media kit</a>
            </li> */}
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/1hV22veQzVhcwxyzejvFL1mGEgcD4NcnF"
              >
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/e/2PACX-1vR8Vm3e_Sy9jBJxiymhduAgm0A8Mvzpcyc5CWcmOIKyamVFONvuPMobHdFHYUd9Sw/pub"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
          <ul>
            <li>Partners</li>
            <li onClick={this.toggleModal}>Want to become a partner ?</li>
            <li>
              <Link to="/whoweare/Board">Board members</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
